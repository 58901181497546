.DesktopSearchInput {
  display: flex;
  width: 100%;
  max-width: $element-max-width-desktop;
  flex-flow: row wrap;
  margin-bottom: $input-margin-bottom;

  &__input {
    height: $input-height;
    width: 100%;
    border: none;
    border-radius: $input-border-radius;
    padding-left: 0.5em;

    &:focus {
      outline: none; // This removes Chrome's default black border
      border: 2px solid $blue;
    }
  }
}
