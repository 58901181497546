.DesktopItemTypeEditButton {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover &__text {
    color: $darkgrey;
  }

  &__icon {
    margin: 0 0.5em;
    color: $orange;
  }

  &__text {
    transition: color 0.2s;
    color: transparent;
    display: none;
    @media screen and (min-width: $tablet) {
      display: inline;
    }
  }

  &__form {
    width: 95vw;
    max-width: 500px;
    background-color: $lightgrey;
    padding: 1.5em;
    border-radius: $input-border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center;
    }

    &__controls {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
