.LoadingPage {
  background: $lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &__logo {
    width: 300px;
    height: 100px;
    background-image: url('/logo_green.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
