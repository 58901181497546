* {
  box-sizing: border-box;
}

.landscape-phone {
  display: none;
}

.large-screen {
  display: none;
}

@media (min-width: $landscape-phone) {
  .landscape-phone {
    display: table-cell;
  }
}

@media (min-width: $tablet) {
  .large-screen {
    display: block;
  }

  .small-screen {
    display: none;
  }
}
