.LoadingSpinner {
  color: $green;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--centered {
    margin: auto;
  }

  &__title {
    font-style: italic;
  }
}
