/* Default colors */
$archive-blue: #6b97aa;
$black: #000000;
$blue: #1276a1;
$green: #617f34;
$lightgreen: #b9d392;
$lightergreen: lighten($lightgreen, 15%);
$lightgrey: #f2f2f2;
$grey: #b3b3b3;
$darkgrey: #666666;
$white: #ffffff;
$lightred: #f1a7b0;
$red: #c32235;
$orange: #ae6d04;
$lightorange: #fbd79a;

/* Width - From Bootstrap (mobile first) */
$desktop-large: 1200px;
$desktop: 992px;
$tablet: 768px;
$landscape-phone: 576px;
$breakpoints: (
  'landscape-phone': $landscape-phone,
  'tablet': $tablet,
  'desktop': $desktop,
  'desktop-large': $desktop-large,
);

// Base margins for content container
$base-margin-top: 1.25em;
$base-margin-left: 3em;
$base-margin-right: 4.5em;
$base-margin-bottom: 1.25em;

// Margins for content
$element-base-margin: 0.6em;
$information-box-margin-right: 0.3em;
$input-margin-bottom: 0.3em;

// Paddings for content
$element-base-padding: 0.6em;

// Widths for content
$element-max-width-desktop: 400px;

/* Fonts */
$font-poppins: 'Poppins', sans-serif;
$font-monospace: 'Lucida Console', 'Monaco', 'Courier New', 'Courier', monospace;
$font-size-large: 1.9rem;
$font-size-semi-large: 1.25rem;
$font-size-medium: 1.125rem;
$font-size-regular: 1rem;

/* Header */
$header-height-mobile: 50px;
$header-height-desktop: 70px;

/* Input & row height */
$input-height: 40px;

/* Input & row border-radius */
$input-border-radius: 6px;

/* Tables */
$table-max-height: calc(100vh - 350px);
$table-cell-border-radius: 5px;
$table-border-width: 5px;
$table-cell-padding: 8px;
$table-heading-font-size: 1rem;
$table-cell-font-size: 1rem;
$table-max-visible-rows: 10; // works with 1rem font sizes only
$table-cell-max-width: 500px;
