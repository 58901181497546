.MobileSmallButton {
  background: $green;
  width: 95%;
  max-width: 400px;
  margin: 0 0.5em;
  height: 45px;
  border: none;
  border-radius: 90px;
  color: $white;
  font-size: $font-size-regular;

  &:hover {
    border: 2px solid $blue;
  }

  &:disabled {
    background: $lightergreen;
    cursor: not-allowed;
  }
}
