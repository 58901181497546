.MobileScanTableRow {
  background-color: $white;
  height: $input-height;
  margin-bottom: 10px;

  td:first-child {
    border-radius: $input-border-radius;
    width: 7px;
    background-color: $red;
  }

  td:nth-child(2) {
    padding-left: 10px;
  }

  td:last-child {
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }
}
