.DesktopSingleWorkPage {
  width: 100%;

  @include single-page-button-group;

  &__statuses {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: $tablet) {
  .DesktopSingleWorkPage {
    &__information {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__statuses {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
}
