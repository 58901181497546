.MobileScanOutForm {
  @include mobile-top-level-container;

  &__saved-info {
    padding: 1em;

    span {
      font-weight: 600;
    }
  }

  .ScannedItemsTable {
    @include mobile-scanned-items-table(360px);

    .cell-style-selected {
      background: $blue;
      color: $white;
    }
  }

  a {
    width: 100%;
  }
}

.inputs {
  @include mobile-form-inputs;
}
