.DesktopInvoiceIndicatorCounter {
  text-align: center;
  position: relative;
  left: $element-base-margin;
  margin-right: -33px; // x-width of Laskutus
  color: $white;
  border-radius: $input-border-radius;
  font-size: $font-size-medium;
  padding-right: $element-base-padding;
  padding-left: $element-base-padding;
  max-width: $element-max-width-desktop;

  &--billing {
    background: $red;
  }

  &--no-billing {
    background: $green;
  }
}
