.DesktopSingleWorkInvoiceIndicator {
  text-align: center;
  color: $white;
  border-radius: $input-border-radius;
  font-size: $font-size-semi-large;
  padding: $element-base-padding;
  max-width: $element-max-width-desktop;

  &--billing {
    background: $red;
  }

  &--no-billing {
    background: $green;
  }
}
