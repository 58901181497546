.DesktopDeviceStatusInformationBox {
  max-width: $element-max-width-desktop;
  background: $white;
  display: flex;
  flex-flow: column nowrap;
  padding: $element-base-padding;
  border-radius: $input-border-radius;
  margin-bottom: $element-base-margin;

  &__title {
    font-weight: bold;
    text-align: center;
    margin: 0;
  }

  &__item-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  @media (min-width: $tablet) {
    margin-left: $element-base-margin;
  }
}
