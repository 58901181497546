.DesktopPageHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-wrap: anywhere;

  &__title {
    font-size: $font-size-large;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (min-width: $tablet) {
      margin-top: 1rem;
    }
  }

  &__subtitle {
    font-size: $font-size-regular;
    font-weight: 500;
    color: $darkgrey;
    margin-top: 0;
  }

  @media screen and (min-width: $tablet) {
    align-items: flex-start;
  }
}
