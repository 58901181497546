.DesktopTableReleaseButton {
  color: $white;
  cursor: pointer;
  border-radius: $input-border-radius;
  border: none;
  padding: $element-base-padding;
  height: $input-height;
  min-width: 100px;

  &--2 {
    background: $orange;

    &:disabled {
      background: $lightorange;
      cursor: not-allowed;
    }
  }

  &--3 {
    background: $green;

    &:disabled {
      background: $lightergreen;
      cursor: not-allowed;
    }
  }
}
