.DesktopContentContainer {
  width: 100%;
  min-height: calc(100vh - #{$header-height-mobile});
  padding: $element-base-padding;
  overflow: hidden;
  margin-top: $header-height-mobile;
}

@media (min-width: $tablet) {
  .DesktopContentContainer {
    display: flex;
    min-height: calc(
      100vh - #{$header-height-desktop} - #{$base-margin-top} - #{$base-margin-bottom}
    );
    flex-wrap: wrap;
    padding: 0;
    margin-top: $base-margin-top;
    margin-right: $base-margin-right;
    margin-left: $base-margin-left;
    margin-bottom: $base-margin-bottom;
  }
}
