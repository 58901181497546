.DesktopAdminUserCreate {
  display: flex;
  flex-flow: row wrap;
  width: 300px;

  &__create {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    margin-bottom: 1.5rem;

    label {
      margin-right: 10px;
    }

    input {
      margin-right: 0;
      margin-left: 0;
    }

    .DesktopSearchInput:nth-child(1) {
      margin-top: 15px;
    }
  }

  &__title {
    font-size: $font-size-regular;
  }

  .DesktopFormButton {
    margin: 15px auto 10px auto;

    @media screen and (min-width: $tablet) {
      margin-left: 0;
    }
  }
}
