#menuToggle {
  display: block;
  position: relative;
  left: $element-base-margin;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: $white;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: $white;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 80vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: -39px 0 0 -10px;
  padding-top: 4em;
  padding-left: 0;
  background: $lightgreen;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

.desktop-mobile-navigation-item {
  text-decoration: none;
  width: 90%;
  display: flex;
  justify-content: center;
  font-size: $font-size-semi-large;
  padding: $element-base-padding;
  color: black;
  background: $lightgrey;
  transition: color 0.3s ease;
  margin-top: $element-base-margin;
  border-radius: 0 99px 99px 0;

  &__sub {
    font-size: $font-size-regular;
    width: 80%;
    margin-top: 0;
    background: $lightergreen;
    border: 2px solid $lightgreen;
    border-left: none;
    border-top: none;
  }

  &--active {
    background: $green;
    color: $white;
  }
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
}

@media (min-width: $tablet) {
  .MobileNav {
    display: none;
  }
}
