.DesktopSingleWorkInvoicePage {
  width: 100%;

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: $landscape-phone) {
  .DesktopSingleWorkInvoicePage {
    &__information {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}
