.QRSticker {
  background-color: white;
  width: 300px;
  height: 300px;
  padding: 20px;
  border-radius: 5px;

  &__inner {
    border: 1px solid $grey;
    width: 260px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    p {
      margin: 0;
      font-size: $font-size-medium;
    }
  }
}
