.DesktopHeader {
  position: fixed;
  width: 100%;
  z-index: 3;
  height: $header-height-mobile;
  background: $green;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__logo {
    height: calc(#{$header-height-mobile} - 20px);
    width: 150px;
    background-image: url('/logo_white.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__user-section {
    display: flex;
    margin-right: $element-base-margin;

    .user-icon {
      padding-right: 0.3em;
    }
  }

  &__change-user-section {
    display: flex;
    align-items: center;
  }

  &__log-out-button,
  &__change-user-button {
    font-size: $font-size-regular;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    background: none;
    outline: none;
    padding: 0;
    padding-right: $element-base-padding;
    border: none;
    color: white;
    cursor: pointer;
  }
}

@media (min-width: $tablet) {
  .DesktopMobileNav {
    display: none;
  }
  .DesktopHeader {
    height: $header-height-desktop;
    position: relative;

    &__logo {
      height: calc(#{$header-height-desktop} - 32px);
      margin-left: 1.5em;
    }
  }
}
