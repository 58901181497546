.DesktopItemTable {
  width: 100%;
  margin-bottom: $element-base-margin;

  &__search {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
}
