.DesktopDeleteButtonTable {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  img {
    margin-bottom: -4px;
  }
}
