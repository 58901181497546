.DesktopItemPage {
  width: 100%;
}

@media (min-width: $tablet) {
  .DesktopItemPage {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
  }
}
