@mixin mobile-top-level-container {
  position: absolute;
  width: 100%;
  display: flex;
  padding-top: 0.5em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@mixin mobile-small-buttons {
  width: 90%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

@mixin mobile-form-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 0.5em;
}

@mixin mobile-tables {
  width: 90%;
  text-align: left;
  margin-top: 30px;
  font-family: $font-poppins;
  border-collapse: separate;
  border-spacing: 0px 10px;

  th {
    font-weight: normal;
  }
}

@mixin desktop-button {
  background: $green;
  font-size: $font-size-medium;
  color: $white;
  margin: $element-base-margin;
  width: 180px;
  height: 45px;
  border: none;
  border-radius: 90px;
  cursor: pointer;

  &--modify {
    background: $orange;
  }

  &--warning {
    background: $red;
  }

  &--archive {
    background: $archive-blue;
  }

  a {
    text-decoration: none;
    color: $white;
  }

  &:disabled {
    background: $lightergreen;

    &:hover {
      border: none;
      cursor: not-allowed;
    }
  }
}

@mixin desktop-form-prompt {
  background: $lightgrey;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: $element-base-padding;
  width: 95vw;
  max-width: 500px;
  border-radius: $input-border-radius;

  &__title {
    margin: 0;
  }

  &__body {
    margin: 0;
    margin-bottom: $base-margin-bottom / 2;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  &__buttons {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    width: 100%;
    justify-content: space-evenly;

    .DesktopPromptButton {
      @include desktop-button;

      &--negative {
        background: $red;
      }
    }
  }
}

@mixin desktop-create-form {
  display: flex;
  justify-content: flex-start;
  flex-flow: column wrap;
  width: 100%;

  .DesktopLinkButton {
    background: $red;
  }

  &__fields {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  @media (min-width: $landscape-phone) {
    justify-content: center;
    max-width: 500px;

    &__fields,
    &__buttons {
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  @media (min-width: $tablet) {
    justify-content: unset;

    &__fields,
    &__buttons {
      justify-content: unset;
    }
  }
}

@mixin desktop-three-column-table {
  width: 100%;
  margin-bottom: $element-base-margin;

  &__search {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__heading-area {
    margin: 0;
  }

  &__heading-table {
    width: 99%;
    text-align: center;
  }

  &__heading-text {
    font-weight: normal;
  }

  &__heading-text:nth-child(1) {
    width: 70%;
  }

  &__heading-text:nth-child(2) {
    width: 15%;
  }

  &__heading-text:nth-child(3) {
    width: 15%;
  }

  &__rows-area {
    height: calc((100vh - #{$header-height-mobile}) * 0.5);
    margin: 0;
    overflow: auto;
    overflow-x: hidden;
  }

  &__rows-table {
    width: 100%;
    margin: 0 -5px;
    border-spacing: 5px 5px;
  }

  @media (min-width: $tablet) {
    &__heading-text:nth-child(1) {
      text-align: left;
    }

    &__rows-area {
      height: calc((100vh - #{$header-height-desktop}) * 0.7);
    }
  }
}

@mixin desktop-three-column-table-row {
  @include cellStyle;

  &__data:nth-child(1) {
    width: 70%;
  }

  &__data:nth-child(2) {
    width: 15%;
  }

  &__data:nth-child(3) {
    width: 15%;
  }
}

@mixin cellStyle {
  &__data {
    background: $white;
    height: $input-height;
    border-radius: $input-border-radius;
    padding: 0 $element-base-padding;

    &--1 {
      color: $white;
      background: $red;
    }

    &--2 {
      color: $white;
      background: $orange;
    }

    &--3 {
      color: $white;
      background: $green;

      &.warehouse-color {
        &--Helsinki {
          background: $green;
        }

        &--Turku {
          background: $lightgreen;
          color: $green;
        }
      }
    }

    &--inv1 {
      color: $white;
      background: $red;
    }

    &--inv2 {
      color: $white;
      background: $green;
    }

    &--archived {
      background: $archive-blue;
      color: $white;

      .archived-icon-container {
        display: flex;
        align-items: center;

        .folder-icon {
          padding-right: 0.2em;
        }
      }
    }
  }

  &__date-input {
    border: 0;
    width: 100%;
    height: 100%;
    text-align: right;

    &:focus {
      outline: none;
    }
  }

  &__checkbox {
    height: 80%;
    width: 100%;
    margin: auto;
    vertical-align: middle;
  }

  &__input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    text-align: center;
    font-weight: bold;
    margin: 0;

    &::-webkit-inner-spin-button {
      opacity: 1;
    }
  }

  &__status {
    padding-right: $element-base-padding;

    &--inv1 {
      color: $red;
    }

    &--inv2 {
      color: $green;
    }
  }

  &__link {
    color: $blue;
    text-decoration: none;
  }

  &__monospace {
    font-family: $font-monospace;
  }
}

@mixin mobile-scanned-items-table($height: 0px) {
  width: 100%;
  height: calc(100vh - #{$height});
  margin-bottom: 1em;
  overflow: hidden;

  .DesktopBaseTable {
    max-height: calc(100vh - #{$height});

    .sticky-table-table {
      padding-bottom: 150px;
    }
  }

  &__title {
    font-size: $font-size-medium;
    padding: 0 0.5em;
    margin-bottom: 0;
  }
}

@mixin single-page-button-group {
  &__buttons {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  @media (min-width: $landscape-phone) {
    &__buttons {
      flex-flow: row nowrap;
    }
  }

  @media (min-width: $tablet) {
    button:nth-child(1) {
      margin-left: 0;
    }
  }
}
