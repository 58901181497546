.SelectInput {
  width: 100%;
  max-width: $element-max-width-desktop;
  display: flex;
  flex-direction: column;
  margin-bottom: $input-margin-bottom;

  &--row {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .react-select-container {
      max-width: 280px;
    }
  }

  .react-select-container {
    width: 100%;
    box-sizing: border-box;

    .react-select__control {
      width: 100%;
      max-width: $element-max-width-desktop;
      min-height: $input-height;
      background: $white;
      cursor: pointer;
      border: none;
      transition: none;
      border-radius: $input-border-radius;

      /*&:hover {
        border: 2px solid $blue;
      } */

      &--is-disabled {
        opacity: 0.5;

        .react-select__value-container {
          .react-select__placeholder {
            visibility: hidden;
          }
        }
      }
    }

    .react-select__menu {
      z-index: 3;

      .react-select__menu-list {
        .react-select__option {
          cursor: pointer;

          &:hover {
            background: $blue;
            color: $white;
          }
        }
      }
    }
  }
}
