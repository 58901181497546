.MainButton {
  background: $green;
  width: 60%;
  height: 50px;
  margin: 20px auto 0 auto;
  border: none;
  border-radius: 90px;
  color: $white;

  a {
    font-size: $font-size-semi-large;
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: $grey;
    color: $white;
  }
}
