.DesktopNavigation {
  display: none;
}

@media (min-width: $tablet) {
  .DesktopNavigation {
    display: block;
    min-width: 220px;
    padding-top: $base-margin-top;
    background-color: $white;

    &__item {
      height: 3em;
      padding-left: 5em;
      display: flex;
      align-items: center;
      color: $blue;
      text-decoration: none;

      &--active {
        background-color: $green;
        border-top-right-radius: 90px;
        border-bottom-right-radius: 90px;
        color: $white;
      }
    }

    &__submenu {
      display: flex;
      flex-direction: column;
      padding-top: 1.25em;

      .submenu-item {
        height: 3em;
        padding-left: 7.25em;
        color: $blue;
        text-decoration: none;
        display: flex;
        align-items: center;

        &--active {
          background-color: $grey;
          padding-top: 0px;
          border-top-right-radius: 90px;
          border-bottom-right-radius: 90px;
          border: 3px solid $blue;
          border-left: none;
          color: $black;
        }
      }
    }
  }
}
