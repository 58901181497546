.LoginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  label {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    input {
      border-radius: 6px;
      border: 1px solid $grey;
      height: 33px;
      padding: 2px 8px;
      width: 234px;
    }
  }
}
