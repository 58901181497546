.DesktopAdminUsersPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: $tablet) {
    align-items: flex-start;
  }

  &__forms {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: $tablet) {
      align-items: flex-start;
    }

    @media screen and (min-width: $desktop) {
      align-items: flex-start;
      flex-direction: row;

      & > * + * {
        margin-left: 2rem;
      }
    }
  }
}
