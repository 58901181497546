.DesktopCreateItemPage {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media (min-width: $tablet) {
    margin-top: 0;
    flex-flow: column nowrap;
    justify-content: flex-start;
  }
}
