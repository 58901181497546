.DesktopDateInput {
  width: 100%;
  max-width: $element-max-width-desktop;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: $input-margin-bottom;

  &__input {
    height: $input-height;
    background: $white;
    padding-left: $element-base-padding;
    width: 100%;
    border: none;
    border-radius: $input-border-radius;

    &:focus {
      outline: none; // This removes Chrome's default black border
      border: 2px solid $blue;
    }
  }
}
