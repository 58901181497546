.MobileScanInForm {
  @include mobile-top-level-container;

  &__saved-info {
    padding: 1em;

    span {
      font-weight: 600;
    }
  }

  .ScannedItemsTable {
    @include mobile-scanned-items-table(320px);
  }

  a {
    width: 100%;
  }
}

.inputs {
  @include mobile-form-inputs;
}
