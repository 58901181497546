.MobileScanOverlay {
  position: fixed;
  z-index: 3;
  top: $header-height-mobile;
  left: 0;
  height: calc(100vh - #{$header-height-mobile});
  width: 100vw;
  background: $lightgrey;
  padding: 0 1em;

  &__title {
    text-align: center;
    font-weight: 400;
    font-size: $font-size-medium;
    margin: 1em 0;
  }

  &__error {
    padding: 2em;
    color: $red;
  }

  &__QrReader {
    margin: 0 auto;
    max-height: calc(100vh - 300px);
    max-width: calc(100vh - 300px);
  }

  &__manual {
    display: flex;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .MobileSmallButton {
      height: 40px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: 0;
      margin-right: 0;
      max-width: 120px;
    }
  }

  &__exit {
    position: relative;
    margin-top: 1.75em;

    .MobileSmallButton {
      position: relative;
      margin: 0;
      width: 100%;
    }
  }

  &__counter {
    position: absolute;
    right: 40px;
    top: 12px;
    color: $green;
    background: $white;
    padding: 0 0.5em;
    border-radius: 3px;
    font-size: $font-size-medium;
  }

  &__amount {
    width: 100%;
    text-align: center;
  }
}
