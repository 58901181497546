.DesktopInvoiceTable {
  width: 100%;

  &__search {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__search-fields {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
  }

  &__clear-search {
    color: $blue;
    padding: $element-base-padding;
    border-radius: $input-border-radius;

    &:hover {
      cursor: pointer;
    }
  }
}
