.DesktopInformationBox {
  width: 100%;
  max-width: $element-max-width-desktop;
  background: $white;
  display: flex;
  flex-flow: column nowrap;
  padding: $element-base-padding;
  border-radius: $input-border-radius;
  margin-bottom: $element-base-margin;
  word-wrap: break-word;

  &__title {
    font-weight: bold;
    margin-right: $information-box-margin-right;
  }

  &__link {
    color: $blue;
    text-decoration: none;
  }
}
