.DesktopContainer {
  background: $lightgrey;
  min-height: calc(100vh - #{$header-height-mobile});

  &__items {
    display: flex;
  }

  @media (min-width: $tablet) {
    min-height: calc(100vh - #{$header-height-desktop});
  }
}
