.DesktopCheckboxContainer {
  width: 100%;
  border-radius: $input-border-radius;
  background: $white;
  padding: $element-base-padding;
  margin-top: $element-base-margin;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media (min-width: $landscape-phone) {
    max-width: 300px;
  }
}
