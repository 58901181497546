.DesktopBaseTable {
  width: 100%;
  max-height: $table-max-height;

  .cell-style {
    @include cellStyle;
  }

  .cell-collapse {
    width: 1px;
  }

  .cell-center {
    text-align: center;
  }

  .sticky-table-table {
    margin-top: -$table-border-width; // "erase" top border to prevent slight table header movement when scrolling
    width: 100%;
    border-collapse: separate;
    border-spacing: $table-border-width;

    > * {
      height: 100%; // rows full height to allow cell content to be full height
    }
  }

  .sticky-table-cell {
    height: 100%;
    border-radius: $table-cell-border-radius;
    border-bottom: none !important;
    padding: $table-cell-padding;
    font-size: $table-cell-font-size;
    white-space: normal;
    max-width: $table-cell-max-width;
    word-wrap: break-word;
    vertical-align: middle;

    a {
      color: $blue;
      text-decoration: none;
    }
  }

  .cell-full {
    padding: 0;
  }

  &__header {
    .sticky-table-cell {
      background: $lightgrey;
      font-size: $table-heading-font-size;
      border-radius: 0;
      vertical-align: bottom;
    }
  }
}
